<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4>Notificações ({{ notifications.length }})</h4>
        </div>
      </div>
      <div v-if="notifications.length !== 0" class="col-sm-6">
        <div class="page-title-box text-right">
          <button @click.prevent="readAllNotifications()" class="btn btn-info">
            <i class="mdi mdi-check-bold"></i> Marcar todas como lidas
          </button>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <b-alert
        v-for="notification in notifications"
        :key="notification.id"
        show
        :variant="notification.read ? 'light' : 'info'"
      >
        <h4 class="alert-heading">
          {{ notification.title }}
          <i v-if="notification.routeName" class="fas fa-external-link-alt"></i>
        </h4>
        <p>
          {{ notification.content }}
        </p>
        <div>
          <button
            v-if="!notification.read"
            @click.prevent="readNotification(notification)"
            class="btn btn-info btn-sm"
            style="position: relative; z-index: 10;"
          >
            <i class="mdi mdi-check-bold"></i> Marcar como lido
          </button>
          <router-link
            v-if="notification.routeName"
            :to="
              notification.routeName
                ? {
                    name: notification.routeName,
                    params: notification.routeParams,
                  }
                : '#'
            "
            class="stretched-link"
          >
          </router-link>
        </div>
      </b-alert>
    </div>
    <div v-if="notifications.length === 0">
      <h1 class="text-center">
        <i class="mdi mdi-alert-circle"></i> Nenhuma notificação encontrada!!
      </h1>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import { mapState } from "vuex";

import * as api from "@/api";

export default {
  page: {
    title: "Notificações",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
  },
  data() {
    return {
      notifications: [],
      timeout: false,
    };
  },

  created() {
    if (!this.currentUser) {
      return;
    }
    this.loadNotifications(true);
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    getNotificationNumber() {
      return this.notifications.length;
    },
  },

  methods: {
    loadNotifications(firstTime = false) {
      api
        .getNotifications({ includeRead: true })
        .then((notifications) => {
          this.notifications = notifications;
          if (firstTime) {
            this.seenNotifications = notifications.map((n) => n.id);
            return;
          }
        })
        .finally(() => {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }

          this.timeout = setTimeout(() => {
            this.loadNotifications();
          }, 3000);
        });
    },

    readNotification(notification) {
      this.notifications = this.notifications.filter(
        (n) => n.id !== notification.id
      );
      api.readNotification(notification.id);
    },

    async readAllNotifications() {
      await api.readNotification("all");
      this.notifications = [];
    },
  },
};
</script>
